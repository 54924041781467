export const NOTIFICATION = {
	TYPE: {
		SUCCESS: 'success',
		ERROR: 'error',
		INFO: 'info',
		WARNING: 'warning',
		OPEN: 'open',
	},
	MESSAGE: {
		SUCCESS: '¡Bien!',
		ERROR: '¡Ups!',
		INFO: 'Información',
		WARNING: 'Advertencia',
		OPEN: 'Abrir',
	},
	ICON:{
		SUCCESS:'https://storage.googleapis.com/tirx-resources-production/61e47f3d-bff8-41ab-a321-c25e04b956cd.png',
		ERROR:'https://storage.googleapis.com/tirx-resources-production/5ba15053-cc87-478e-84fa-bd74b905c067.png'
	}
};

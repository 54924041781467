/** @format */

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Images from "../../../../../components/Commons/Images";
import MetaDescription from "../../../../../components/Commons/MetaDescription";

import { GetLocalStorageValue } from "../../../../../_config/adapters/local-storage/get-local-storage-value.adapter";

import { ENV_IMG } from "../../../../../components/Commons/Hooks/Variables/Enviroment";

import "./style.scss";

class ErrorTransaction extends Component {
  state = {
    error: JSON.parse(localStorage.getItem("paymentData")),
    emailPharmacy: null,
  };

  componentDidMount() {
    const emailPharmacy = GetLocalStorageValue('emailPharmacy');
    this.setState({ emailPharmacy });

    if (!this.state.error) {
      window.location.href = "/";
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="ph-error-global-container">
        <MetaDescription
          title={
            "Error en Procesar Compra - Red de Farmacias Online | Pharol, encuentra tu Farmacia"
          }
          name={"description"}
          content={
            "Red de Farmacias Online | Pharol - Por favor vuelva a intentar."
          }
        />
        <section className="ph-error-main-container">
          <div className="ph-error-inner-container">
            <Images
              classContent={"ph-error-img-container"}
              classImage={"ph-error-img"}
              webp={`${ENV_IMG}/react-checkout/master-recipe/error.webp`}
              jp2={`${ENV_IMG}/react-checkout/master-recipe/error.jxr`}
              jxr={`${ENV_IMG}/react-checkout/master-recipe/error.jp2`}
              default={`https://storage.googleapis.com/tirx-resources-production/5ba15053-cc87-478e-84fa-bd74b905c067.png`}
            />
            <h1 className="ph-error-succes-title">¡Error de transacción!</h1>
            <h4 className="ph-error-description-span">
              <span className="ph-error-description-span-i">*</span>
              {t('checkout.transaction.error.description')}
              <a href={`mailto:${this.state.emailPharmacy}`}>
                <span className="ph-error-description-contact">
                  {this.state.emailPharmacy}
                </span>
              </a>
            </h4>
            <div className="ph-error-payment-button-container">
              <Link className="ph-error-payment-button" to="/">
                <span className="ph-error-payment-button-span">
                  Volver al Inicio
                </span>
              </Link>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default withTranslation('global')(ErrorTransaction); 
/** @format */

import React from 'react';

import { setGlobal } from 'reactn';

import { Link, useHistory } from 'react-router-dom';
import { ENV_IMG } from '../../../../components/Commons/Hooks/Variables/Enviroment';
import Images from '../../../../components/Commons/Images';
import Notify from '../../../../components/Commons/Notify';
import CustomButton from '../../../../v2-components/Antd/Button';
import './style.scss';

export default function Error(props) {
	const history = useHistory();

	const handleDeleteCart = () => {
		localStorage.setItem('cart', JSON.stringify([]));
		Notify({
			title: `¡Bien!`,
			colorTitle: 'ph-main-notification-error-title',
			description: `Tu carrito ha sido borrado de forma exitosa, serás redirigido al Inicio`,
			time: 3,
			image: 'warning',
			placement: 'topRight',
		});
		setGlobal(() => ({
			carQuantity: 0,
		}));
		setTimeout(() => {
			history.push('/');
		}, 3000);
	};

	return (
		<div className={`${props.data.electronicCart?.container ? 'ph-bag-no-products-electronic-main-container' : 'ph-bag-no-products-main-container'}`}>
			<div className="ph-bag-no-products-container">
				<div className="ph-bag-no-products-inner-container">
					<Images
						classContent={'ph-bag-no-products-image-container'}
						classImage={'ph-bag-no-products-image'}
						default={`https://storage.googleapis.com/tirx-resources-production/f3b6eee4-7e09-4147-8a1d-f341f55215d0.png`}
						title={'bolsa'}
					/>
					<h3 className="ph-bag-no-products-title">{props.data.title}</h3>
					<h3 className="ph-bag-no-products-subtitle">{props.data.description}</h3>
					<Link to="/">
						<h3 className="ph-bag-no-products-link">Seguir Comprando</h3>
					</Link>
					<h3 className="ph-bag-no-products-message">*Puede que tu Bolsa contiene productos inválidos, pulsa "Borrar Bolsa" para corregir el problema:</h3>
					<CustomButton className={'button-primary'} onClick={() => handleDeleteCart()}>
						Borrar Bolsa
					</CustomButton>
				</div>
			</div>
		</div>
	);
}

import { AxiosAdapter } from '../../../../_config/v2/adapters/axios/axios.adapter';
import { Http } from '../../../../_config/v2/adapters/axios/axios.protocols';
import { Enviroment } from '../../../../_config/constants/enviroment-v2.constant';

export class Service {
	async getClaimByClaimId(id) {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.API_PHARMACY}/claim/${id}`,
			params: { pharmacy: Enviroment.ROOT_PHARMACY, platform: Enviroment.ROOT_PLATFORM, country: Enviroment.COUNTRY },
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}
}

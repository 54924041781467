/** @format */

import React from 'react';
import { useHistory } from 'react-router-dom';
import Images from '../../components/Commons/Images';
import MetaDescription from '../../components/Commons/MetaDescription';
import CustomButton from '../../v2-components/Antd/Button';
import './style.scss';

export default function NotFound({ statusCode }) {
	const history = useHistory();

	return (
		<section className="ph-not-found-global-container">
			<MetaDescription
				title={'Pagina no encontrada - Red de Farmacias Online | Pharol, encuentra tu Farmacia'}
				name={'description'}
				content={'Red de Farmacias Online | Pharol - Gracias por confiar en Pharol.'}
			/>
			<section className="ph-not-found-inner-container">
				<div className="ph-not-found-main-container">
					<Images
						classContent={'ph-not-found-img-container'}
						classImage={'ph-not-found-img'}
						default={ statusCode === 500 
							? 'https://storage.googleapis.com/tirx-resources-production/84527077-7348-4e5b-9ae5-c82c04fc93fa.png '
							: 'https://storage.googleapis.com/tirx-resources-production/28d6f23b-23f7-46b8-a48d-b02525941bb5.png'
						}
					/>
					<h1 className="ph-not-found-title">¡Pagina no Encontrada!</h1>
					<h4 className="ph-not-found-description">
						La ruta o enlace que intentas acceder no se encuentra disponible, te invitamos a ver nuestros productos y servicios desde nuestra página de inicio:
					</h4>
					<div className="ph-not-found-payment-button-container">
						<CustomButton className={'button-primary'} onClick={() => history.pushState('/')}>
							Volver al Inicio
						</CustomButton>
					</div>
					<div className="ph-not-found-payment-button-container"></div>
				</div>
			</section>
		</section>
	);
}

import { notification } from 'antd';
import CustomImage from '../Image'
import { NOTIFICATION } from './constant';
import './style.scss';

export const CustomNotification = ({ type, message, description, duration = 4.5 }) => {
	return notification[type]({
		message: message,
		description: description,
		duration: duration,
		icon: NOTIFICATION.ICON[type.toUpperCase()] ? <CustomImage
															src={NOTIFICATION.ICON[type.toUpperCase()]}
															alt='Notification icon'
															width={24}
														/>
							: null	
	});
};

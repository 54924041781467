/** @format */

import React from 'react'

import { useGlobal } from 'reactn'

import { Form, Input, Drawer, Button } from 'antd'

import { ArrowLeftOutlined } from '@ant-design/icons'

/////// DEPREACATED ////////////////////////////////////////////////////////
/* import Images from '../../../../../../Images'
import lupaVerde from '../../../../../../../../_config/constants/img/lupa-verde.png'
import lupaNaranja from '../../../../../../../../_config/constants/img/lupa-naranja.svg'
import { ENV_COUNTRY, ENV_IMG_HEADER } from '../../../../../../Hooks/Variables/Enviroment' */

import Search from '../../../../../components/Search'
import { ReactComponent as IconSearch } from '../../../../../../../../v2-config/icons-svg/search.svg'

import './style.scss'

const SeachButton = () => {
	const [isVisible, setVisible] = useGlobal('searchDrawer')

	const handleSearchDrawer = (item) => {
		setVisible(item)
	}

	return (
		<>
			<Form onClick={() => handleSearchDrawer(true)}>
				<Form.Item className='ph-search-mobile-button-form-item'>
					<Input type='text' placeholder='Búsqueda...' className='ph-search-mobile-button' />
					
					{/* -------------  DEPRECATED ---------------------------
					<Images
						classContent={'ph-search-mobile-button-icon-pharmacy-button-container'}
						classImage={'ph-search-mobile-button-icon-pharmacy-button'}
						webp={`${ENV_IMG_HEADER}/pharol-search-lupa-gris.webp`}
						jp2={`${ENV_IMG_HEADER}/pharol-search-lupa-gris.jp2`}
						jxr={`${ENV_IMG_HEADER}/pharol-search-lupa-gris.jxr`}
						default={`${ENV_COUNTRY === "CL" ? lupaNaranja : lupaVerde}`}
						title={'Buscar Productos'}
					/> */}
					<div className='ph-search-mobile-button-icon-pharmacy-button-container'>
						<IconSearch className={'mobile-icons-theme-search'} />
					</div>
				</Form.Item>
			</Form>
			<Drawer
				placement='left'
				closable={false}
				visible={isVisible}
				className='ph-search-mobile-button-search-drawer'>
				<Button
					className='ph-search-mobile-button-close-drawer'
					onClick={() => handleSearchDrawer(false)}
					icon={<ArrowLeftOutlined />}
				/>
				<Search />
			</Drawer>
		</>
	)
}
export default SeachButton

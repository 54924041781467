/** @format */

import React, { Component } from 'react'

//import Spin from 'antd/lib/spin'
//import { LoadingOutlined } from '@ant-design/icons'
import CustomImage from '../../../v2-components/Antd/Image'

import './style.scss'

export default class loading extends Component {
	render() {
		return (
			<>
				<div className='ph-loading-container'>
					{/* <Spin
						indicator={<LoadingOutlined className='ph-loading-image' />}
						className='ph-loading-icon'
					/> */}
					<CustomImage 
						src={'https://storage.googleapis.com/tirx-resources-production/8039fc50-9ea8-4790-a6ce-1fe3a62d6710.gif'}
						alt='loading'
						width={250}
					/>
				</div>
			</>
		)
	}
}

import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col } from 'antd';
//import { CloseOutlined } from '@ant-design/icons';
import { ReactComponent as CloseBorderedIcon } from '../../v2-config/icons-svg/X_popup.svg'
import { Service } from './service';
import CustomCarousel from '../Antd/Carousel';
import './style.scss';

export default function PopUpModal(props) {
	const [classActive, setClassActive] = useState('ph-pop-v2-card-container-visible');
	const [sliders, setSliders] = useState([]);
	const [isLoading, setLoading] = useState(true);

	useEffect(() => {
		new Service().getTemplate({ template: props.template, location: props.location }).then((response) => {
			if (response.length > 0) {
				setSliders([...response]);
				setLoading(false);
			}
		});
	}, [props.template, props.location]);

	if (isLoading) {
		return Fragment;
	}

	const handleCloseModal = () => {
		setClassActive('');
	};

	return (
		<div className={`ph-popup-v2-card-container ${classActive}`}>
			<Row>
				<div className="ph-popup-v2-card-close" onClick={handleCloseModal}>
				{/* 	<CloseOutlined className="ph-popup-v2-card-close-icon" /> */}
					<CloseBorderedIcon className="ph-popup-v2-card-close-icon"/>
				</div>
				<Col className="ph-popup-v2-card-column">
					<CustomCarousel autoplay={true} className={'popup-slider-internal'}>
						{sliders.map((item, index) => (
							<div key={index} onClick={() => window.open(item.link)}>
								<img src={item.image} alt={item.template} className="ph-popup-v2-card-image" />
							</div>
						))}
					</CustomCarousel>
					;
				</Col>
			</Row>
		</div>
	);
}

/** @format */

import { Col, Row } from 'antd';
import {useState} from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { IMAGES } from '../../../../../../v2-config/constants/images.constant';
import Spacer from '../../../../Spacer';
import Address from '../../../components/Address';
import Search from '../../../components/Search';
import LoginUser from './components/LoginUser';
import UserActions from './components/UserActions';
import CustomDrawer from '../../../../../../v2-components/Antd/Drawer';
import Images from '../../../../Images';
import menuDisabled from '../../../../Assets/Icons/Lineals/Disabled/menu.svg'
import { RightOutlined } from '@ant-design/icons'
import './style.scss';
import CustomButton from '../../../../../../v2-components/Antd/Button';

export default function NavBarDesktop(props) {
	const history = useHistory();
	const [open, setOpen] = useState(false);
	const [openSubDrawer, setOpenSubDrawer] = useState(false);
	const [category, setCategory] = useState([]);
	const [subCategory, setSubCategory] = useState([]);
	const [wraper1ZIndex, setWraper1ZIndex] = useState(false);

	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
	};

	const onCloseSubDrawer = () => {
		setWraper1ZIndex(false);
		setOpenSubDrawer(false);
	};

	const navigateToCategory = async (category, subCategory) => {
		await history.push('/');
		await history.push(`/categories/${category && category.slug}${subCategory ? `/${subCategory.slug}` : ''}`);
		onCloseSubDrawer();
		setWraper1ZIndex(false);
		onClose();
	};

	const openSubCategories = (category) => {
		setCategory(category);
		setSubCategory(category.children);
		setTimeout(() => {
			setWraper1ZIndex(true);
		}, 200);
		setOpenSubDrawer(true);
	};

	return (
		<>
			<div className="ph-header-space-vertical-web-container"></div>
			<div className={`ph-header-global-container`}>
				<div className="ph-header-main-container">
					<Row className="ph-header-container">
						<Col span={5} className="ph-nav-container">
							<NavLink to="/">
								<img src={IMAGES.LOGO_LIGHT_BACKGROUND} alt="logo" style={{ height: '39px' }} />
							</NavLink>
						</Col>
						<Col span={13}>
							<Search />
						</Col>
						<Col span={1}></Col>
						<Col span={5} style={{ textAlign: 'right' }}>
							<LoginUser />
						</Col>
					</Row>
					<Row style={{ marginTop: '10px' }}>
						<Col span={5}>
							<Address />
						</Col>
						<Col span={13} className='ph-nav-buttons-container'>
							<CustomButton
								onClick={showDrawer}
								type="text"
								className='categories-button-container'
							>
								<Images classContent={'ph-sidebar-actions-icon-container'} classImage={'ph-sidebar-actions-icon'} title={'menu'} default={menuDisabled} />
								<p className="navlink-name">
									Categorías
								</p>
							</CustomButton>
							<NavLink to={`/contact`}>
								<p className="navlink-name">Contacto</p>
							</NavLink>
						</Col>

						<Spacer />
						<UserActions />
					</Row>
				</div>
			</div>

			<CustomDrawer
				title={<h2 className='ph-drawer-title'>Categorías</h2>}
				placement="left"
				onClose={onClose}
				visible={open}
				width={320}
				push={false}
				style={{
					zIndex: wraper1ZIndex ? "1000" : "1002",
					top: '100px',
					height: 'calc(100vh - 100px)'
				}}
				bodyStyle={{
					padding: "8px"
				}}
			>
				{props.navBar && props.navBar.menu_marketplace.find(obj => obj.id === 'categories').children.map((category, index) => (
					<Row key={`category-item:${category.id}-${index}`} className='ph-drawer-container-items' onClick={() => openSubCategories(category)}>
						<p className='ph-drawer-text-items'>{category.name}</p>
						<RightOutlined />
					</Row>
				))}
			</CustomDrawer>
			<CustomDrawer
				title={<h2 className='ph-drawer-title'>{category.name}</h2>}
				visible={openSubDrawer}
				placement="left"
				onClose={onCloseSubDrawer}
				width={640}
				contentWrapperStyle={{
					marginLeft: openSubDrawer && "320px"
				}}
				push={true}
				style={{
					zIndex: "1001",
					top: '100px',
					height: 'calc(100vh - 100px)'
				}}
				bodyStyle={{
					display: "flex",
					flexDirection: "column",
					flexWrap: "wrap",
					justificateContent: "space-between",
					padding: "8px"
				}}
			>
				<Row className='ph-drawer-container-items' onClick={() => navigateToCategory(category)}>
					<p className='ph-drawer-text-items'>Ver todo {category.name}</p>
				</Row>
				{subCategory.map((subCategory, index) => (
					<Row 
						key={`subcategory-item:${subCategory.id}-${index}`} 
						className='ph-drawer-container-items' 
						onClick={() => navigateToCategory(category, subCategory)}
					>
						<p className='ph-drawer-text-items'>{subCategory.name}</p>
					</Row>
				))}
			</CustomDrawer>
		</>
	);
}

/** @format */

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Events } from '../../../../../_config/adapters/google-tag-manager/events.constant';
import { PurchaseAdapter } from '../../../../../_config/adapters/google-tag-manager/purchase-adapter';
import { amountProductsCart } from '../../../../../components/Commons/Hooks/Functions/AmountProductsCart';
import { PixelFacebook } from '../../../../../components/Commons/Hooks/Functions/PixelFacebook';
import { ENV_IMG, ENV_ISO_CURRENCY } from '../../../../../components/Commons/Hooks/Variables/Enviroment';
import Images from '../../../../../components/Commons/Images';
import Loading from '../../../../../components/Commons/Loading';
import MetaDescription from '../../../../../components/Commons/MetaDescription';
import { RemoveLocalStorage } from '../../../../../components/HooksCheckout/Functions/RemoveLocalStorage';
import CustomButton from '../../../../../v2-components/Antd/Button';
import servicesSuccess from './services';
import { GetLocalStorageValue } from '../../../../../_config/adapters/local-storage/get-local-storage-value.adapter';
import './style.scss';
import ScrollToTop from '../../../../../v2-components/ScrollToTop';

class SuccessTransaction extends Component {
	state = {
		success: JSON.parse(localStorage.getItem('paymentData')),
		order: null,
		emailPharmacy: null,
	};
	async componentDidMount() {
		const emailPharmacy = GetLocalStorageValue('emailPharmacy');

		this.setState({ emailPharmacy })

		// if (localStorage.getItem('fpayUrl')) { // FIX
		if (localStorage.getItem('paymentData')) {
			localStorage.removeItem('cart');
			await servicesSuccess.getFpayPayment();
		}

		if (this.state.success) {
			await servicesSuccess.getPurchase(this.state.success).then((response) => {
				if (response) {
					PurchaseAdapter({ event: Events.purchase, order: response });
					this.setState({ order: response });
					let productsIDs = [];
					response.products.forEach((item) => {
						if (item.id_pharol) {
							productsIDs.push(`${item.id_pharol}`);
						}
					});
					PixelFacebook('Purchase', {
						content_ids: productsIDs,
						content_type: 'product_group',
						currency: ENV_ISO_CURRENCY,
						num_items: amountProductsCart(),
						value: parseInt(response.total).toFixed(2),
					});
				}
			});
		} else {
			window.location.href = '/';
		}
	}
	componentWillUnmount() {
		RemoveLocalStorage();
	}

	render() {
		const { t } = this.props;

		if (!this.state.order) {
			return <Loading />;
		} else {
			return (
				<section className="ph-success-global-container">
					<ScrollToTop />
					<MetaDescription
						title={'Gracias por tu compra - Red de Farmacias Online | Pharol, encuentra tu Farmacia'}
						name={'description'}
						content={'Red de Farmacias Online - Gracias por confiar en Pharol.'}
					/>
					<section className="ph-success-inner-container">
						<div className="ph-success-main-container">
							{this.state.order.trx_id || (!this.state.order.trx_id && this.state.order.payment_method_name?.includes('mercadopago')) ? (
								<Images
									classContent={'ph-succes-img-container'}
									classImage={'ph-succes-img'}
									webp={`${ENV_IMG}/react-checkout/master-recipe/succes.webp`}
									jp2={`${ENV_IMG}/react-checkout/master-recipe/succes.jxr`}
									jxr={`${ENV_IMG}/react-checkout/master-recipe/succes.jp2`}
									default={`https://storage.googleapis.com/tirx-resources-production/61e47f3d-bff8-41ab-a321-c25e04b956cd.png`}
								/>
							) : (
								<Images
									classContent={'ph-error-img-container'}
									classImage={'ph-error-img'}
									webp={`${ENV_IMG}/react-checkout/master-recipe/error.webp`}
									jp2={`${ENV_IMG}/react-checkout/master-recipe/error.jxr`}
									jxr={`${ENV_IMG}/react-checkout/master-recipe/error.jp2`}
									default={`https://storage.googleapis.com/tirx-resources-production/5ba15053-cc87-478e-84fa-bd74b905c067.png`}
								/>
							)}
							{this.state.order.trx_id || (!this.state.order.trx_id && this.state.order.payment_method_name?.includes('mercadopago')) ? (
								<h1 className="ph-succes-title">{t('checkout.transaction.success.title.success')}</h1>
							) : (
								<h1 className="ph-succes-title">{t('checkout.transaction.success.title.error')}</h1>
							)}
							<h3 className="ph-succes-name">
								{this.state.order.info.first_name} {this.state.order.info.last_name}
							</h3>
							<h3 className="ph-succes-mail">{this.state.order.info.email}</h3>
							<h3 className="ph-succes-oc">
								{t('checkout.transaction.success.numberOrder')}:
								<span className="ph-succes-span-oc">
									{this.state.order.trx_id ? (
										<> {this.state.order.trx_id}</>
									) : (
										<>{this.state.order.payment_method_name?.includes('mercadopago') ? '...Procesando tu No. de Orden...' : '...Ocurrio un Error...'}</>
									)}
								</span>
							</h3>
							{this.state.order.trx_id || (!this.state.order.trx_id && this.state.order.payment_method_name?.includes('mercadopago')) ? (
								<>
									<h4 className="ph-succes-description">
										Estamos muy contentos y agradecidos de que nos hayas preferido ¡Te estaremos esperando para tu próxima compra!
									</h4>
									<h4 className="ph-succes-description">Revisa tu correo electrónico verifica tu productos y numero de orden.</h4>
								</>
							) : (
								<h4 className="ph-succes-description">Disculpa las molestias ocasionadas.</h4>
							)}
							<h4 className="ph-succes-description-span">
								<span className="ph-succes-description-span-i">*</span>
								{t('checkout.transaction.success.description')}
								<a href={`mailto:${this.state.emailPharmacy}`}>
									<span className="ph-succes-span">{this.state.emailPharmacy}</span>
								</a>
							</h4>
							<CustomButton className={'button-primary'} onClick={() => (window.location.href = '/')}>
								Volver al Inicio
							</CustomButton>
							{/* {this.state.order.trx_id && (
								<div className="ph-succes-payment-button-container">
									<Link className="ph-succes-payment-button-tracking" to="/tracking">
										Ir a Tracking
									</Link>
								</div>
							)} */}
							{/*Se oculta hasta resolver la parte del trackeo*/}
						</div>
					</section>
				</section>
			);
		}
	}
}
export default withTranslation('global')(SuccessTransaction);

/** @format */

import React from 'react';
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';

import Layout from 'antd/lib/layout';

import Images from '../../../../components/Commons/Images';
import MetaDescription from '../../../../components/Commons/MetaDescription';
import { ENV_IMG } from '../../../../components/Commons/Hooks/Variables/Enviroment';
import CustomButton from '../../../../v2-components/Antd/Button';

import './style.scss';

const { Content } = Layout;

const ScheduleSuccess = () => {
	const { t } = useTranslation('global');
	const history = useHistory();

	const handleRedirect = () => {
		history.push('/');
	};

	return (
		<>
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<Content className="ph-preorder-schedule-succes-global-container">
				<div className="ph-preorder-schedule-success-main-container">
					<section className="ph-preorder-schedule-success-inner-container">
						<Images
							classContent={'ph-preorder-schedule-success-img-container'}
							classImage={'ph-preorder-schedule-success-img'}
							webp={`${ENV_IMG}/react-checkout/master-recipe/succes.webp`}
							jp2={`${ENV_IMG}/react-checkout/master-recipe/succes.jxr`}
							jxr={`${ENV_IMG}/react-checkout/master-recipe/succes.jp2`}
							default={`https://storage.googleapis.com/tirx-resources-production/61e47f3d-bff8-41ab-a321-c25e04b956cd.png`}
						/>
						<h1 className="ph-preorder-schedule-success-title">¡Tu horario ha sido ingresado correctamente!</h1>
						<p className="ph-preorder-schedule-success-subtitle">Tu pedido será enviado a partir de la fecha ingresada.</p>
						<h4 className="ph-preorder-schedule-success-description-span">
							<span className="ph-preorder-schedule-success-description-span-i">*</span>
							{t('checkout.transaction.error.description')}
							<a href={`mailto:${t('main.email')}`}>
								<span className="ph-preorder-schedule-success-description-contact">{t('main.email')}</span>
							</a>
						</h4>
					</section>
					<div className="ph-preorder-schedule-success-buttons-container">
						<CustomButton className={'button-primary'} onClick={handleRedirect}>
							Volver al inicio
						</CustomButton>
					</div>
				</div>
			</Content>
		</>
	);
};

export default ScheduleSuccess;

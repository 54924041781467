import { ENV_ELASTIC_INDEX, ENV_ELASTIC_INDEX_SEARCH } from '../../components/Commons//Hooks/Variables/Enviroment';
export const Country = {
	CL: {
		handleElasticPrecision: () => {
			return ENV_ELASTIC_INDEX;
		},
		handleFilters: (params, pharmacy) => {
			let filters = [];
			filters.push({
				field: 'pharmacy',
				values: [pharmacy],
			});
			filters.push({
				field: 'slug_master_category',
				values: [`${params.category}`],
			});
			if (params.subCategory) {
				filters.push({
					field: 'slug_category',
					values: [params.subCategory],
				});
			}
			/* filters.push({
				field: 'min_price',
				values: [0],
				type: 'none',
			}); */
			return filters;
		},
		handleSort: () => {
			let sort = [
				{
					validate_recipe: 'asc',
				},
				{
					stock: 'desc',
				},
			];
			return sort;
		},
	},
	AR: {
		handleElasticPrecision: () => {
			return ENV_ELASTIC_INDEX_SEARCH;
		},
		handleFilters: (params) => {
			let filters = [];
			filters.push({
				field: 'slug_master_category',
				values: [`${params.category}`],
			});
			if (params.subCategory) {
				filters.push({
					field: 'slug_category',
					values: [params.subCategory],
				});
			}
			filters.push({
				field: 'min_price',
				values: [0],
				type: 'none',
			});
			return filters;
		},
		handleSort: () => {
			let sort = [
				{
					available: 'desc',
				},
			];
			return sort;
		},
	},
};

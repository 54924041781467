/** @format */

import React from 'react';
import { Row, Col, Spin, notification } from 'antd';
import { LoadingOutlined, EnvironmentFilled } from '@ant-design/icons';
import { formatPrice } from '../../../FormatPrice';
import Images from '../../../../../Images';
import { ENV_IMG_ROUTE, ENV_COUNTRY } from '../../../../Variables/Enviroment';
import CustomButton from '../../../../../../../v2-components/Antd/Button';
import './style.scss';

export default function CartNotication(item, data) {
	const handleAmountItemsCart = () => {
		return JSON.parse(localStorage.getItem('cart')).reduce((sum, item) => sum + item.quantity, 0);
	};

	const handleTotalPriceCart = () => {
		return JSON.parse(localStorage.getItem('cart')).reduce((sum, item) => sum + item.sub_total, 0);
	};

	const handleBagLink = () => {
		notification.close(1);
		return (window.location.href = '/cart');
	};

	const { nearest_local } = JSON.parse(localStorage.getItem('mapPharmacy')).lenght > 0 && JSON.parse(localStorage.getItem('mapPharmacy'))[0];

	console.log('[ITEM]', item);
	console.log('[DATA]', data);

	notification.open({
		duration: 13,
		className: 'ph-cart-notification-main-container',
		description: (
			<>
				<div className="ph-cart-notification-main-title-container">
					<h1 className="ph-cart-notification-main-title">¡Producto agregado a la Bolsa!</h1>
				</div>
				<Row className="ph-cart-notification-description-main-container">
					<Col span={4} className="ph-cart-notification-image-main-container">
						<Images
							classContent={'ph-cart-notification-image-container'}
							classImage={'ph-cart-notification-image'}
							webp={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${item.id_pharol}.webp`}
							default={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${item.id_pharol}.png`}
							alt={item.editable_name}
							title={item.editable_name}
						/>
					</Col>
					<Col span={20}>
						<div className="ph-cart-notification-pharmacy">
							<Images
								classContent={'ph-cart-notification-pharmacy-image-container'}
								classImage={'ph-cart-notification-pharmacy-image'}
								default={item.pharmacy_image}
								alt={item.pharmacy}
								title={item.pharmacy}
							/>
							<p className="ph-cart-notification-pharmacy-title">{nearest_local?.name_local}</p>
						</div>
						<h2 className="ph-cart-notification-title">{item.name}</h2>
						<div className="ph-cart-notification-quantity-container">
							<p className="ph-cart-notification-quantity-title">Cantidad:</p>
							<p className="ph-cart-notification-quantity-description">{item.quantity}</p>
						</div>
						<div className="ph-cart-notification-price-container">
							<p className="ph-cart-notification-price-title">Por unidad:</p>
							<p className="ph-cart-notification-price-description">{formatPrice(item.unit_price)}</p>
						</div>
						{item.quantity > 1 && (
							<div className="ph-cart-notification-price-container">
								<p className="ph-cart-notification-price-title">Sub-total:</p>
								<p className="ph-cart-notification-price-description">{formatPrice(item.sub_total)}</p>
							</div>
						)}
						{data ? (
							<>
								{data.response === true ? (
									<div>
										<h4 className="ph-cart-notification-response-positive-title" style={{ color: data.color_distance }}>
											<EnvironmentFilled className="ph-cart-notification-response-positive-icon" /> {data.message} {data.distance_text}
										</h4>
									</div>
								) : (
									<div className="ph-cart-notification-no-response-container">Error al consultar locales.</div>
								)}
							</>
						) : (
							<div className="ph-cart-notification-loading-container">
								<Spin indicator={<LoadingOutlined spin />} />
							</div>
						)}
					</Col>
				</Row>
				<hr className="ph-cart-notification-separator" />
				<Row>
					<Col span={12} className="ph-cart-notification-total-container">
						<Row>
							<p className="ph-cart-notification-total-title">Productos</p>
							<p className="ph-cart-notification-total-subtitle">({handleAmountItemsCart()}):</p>
						</Row>
						<Row>
							<p className="ph-cart-notification-total-description">{formatPrice(handleTotalPriceCart())}</p>
						</Row>
					</Col>
					<Col span={12} className="ph-cart-notification-total-button-main-container">
						<div onClick={() => handleBagLink()}>
							<CustomButton className={'button-primary'}>IR A LA BOLSA</CustomButton>
						</div>
					</Col>
				</Row>
			</>
		),
		key: 1,
		placement: 'topRight',
	});
}

/** @format */

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Row from 'antd/lib/row';
import Col from 'antd/lib/col';

import NotFound from '../not-found/';

import ProductDetail from './components/ProductDetail';
import RequestRecipe from './components/RequestRecipe';
import PharmacySelect from './components/PharmacySelect';
import DeliverySchedule from './components/DeliverySchedule';
import SuccesRecipeObjected from './components/SuccesRecipeObjected';
import MainTitlePage from '../../components/Commons/MainTitlePage';
import MetaDescription from '../../components/Commons/MetaDescription';
import Loading from '../../components/Commons/Loading';

import { serviceGetProduct, serviceSendRecipe } from './services';

import './style.scss';
import CustomButton from '../../v2-components/Antd/Button';

export default function ObjectedRecipe() {
	const { t } = useTranslation('global');
	const { trx_id, code_internal } = useParams();
	const [isProductOrder, setProductOrder] = useState({});
	const [isLoading, setIsLoading] = useState({
		view: true,
		button: false,
	});
	const [isSend, setIsSend] = useState(false);
	const [isInfoSelected, setInfoSelected] = useState({
		recipe: {},
		date: '',
		time: '',
	});

	useEffect(() => {
		let mounted = true;
		(async () => {
			let responseOrder = await serviceGetProduct({ trx_id, code_internal });
			if (!mounted) return;
			setProductOrder(responseOrder);
			setIsLoading((pvSt) => ({ ...pvSt, view: false }));
		})();
		return () => (mounted = false);
	}, [trx_id, code_internal]);

	const handleSendData = async () => {
		setIsLoading((pvSt) => ({ ...pvSt, button: true }));
		let response = await serviceSendRecipe({
			...isInfoSelected,
			...isProductOrder,
			code_internal,
		});
		setIsLoading((pvSt) => ({ ...pvSt, button: false }));
		if (response) setIsSend(true);
	};

	if (isLoading.view) {
		return <Loading />;
	}
	if (isSend) {
		return <SuccesRecipeObjected trx_id={trx_id} />;
	}
	if (isProductOrder.statusCode === 400 || isProductOrder.statusCode === 500) {
		return <NotFound statusCode={isProductOrder.statusCode}/>;
	}
	return (
		<>
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<div className="ph-objected-recipe-global-container">
				<div className="ph-objected-recipe-global-inner-container">
					<MainTitlePage
						rootImage={true}
						title={'Receta Objetada'}
						descriptionPage={'Adjunte su prescripción médica'}
						image={'react-checkout/magistral-recipe/objected-recipe'}
					/>
					<div className="ph-objected-recipe-main-container">
						<h1 className="ph-objected-recipe-main-title">¡Sube tu receta!</h1>
						<span className="ph-objected-recipe-main-subtitle">
							Sabemos lo importante que son tus medicamentos para ti. A continuación podrás subir una nueva imagen de tu receta y seleccionar una nueva fecha de
							entrega de tu orden de compra.
						</span>
						<Row>
							{isProductOrder.info.type_retirement === 'farmacia' ? (
								<Col xs={24}>
									<PharmacySelect order={isProductOrder} setInfoSelected={setInfoSelected} />
								</Col>
							) : (
								<Col xs={24}>
									<DeliverySchedule order={isProductOrder} setInfoSelected={setInfoSelected} />
								</Col>
							)}
						</Row>
						<Row>
							<Col xs={24}>
								<div className="ph-upload-recipe-container">
									<RequestRecipe title="Receta" setInfoSelected={setInfoSelected} />
								</div>
							</Col>
							<Col xs={24}>
								<div className="ph-objected-recipe-inner-container">
									<ProductDetail product={isProductOrder.products[0]} info={isProductOrder.info} />
								</div>
							</Col>
						</Row>
						<div className="ph-objected-recipe-button-container">
							<CustomButton type="button-primary" onClick={handleSendData} loading={isLoading.button} className={'primary'}>
								Continuar
							</CustomButton>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
